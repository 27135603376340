<template>
    <v-container fluid>
        <v-form @submit.prevent="getBrands('search')">
            <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'brand.create'}"
                   color="indigo" fab fixed top right class="v-btn--add-form-top white--text">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="10">
                                <v-subheader class="headline">{{ $t('search_brand') }}</v-subheader>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="2" class="text-right">
                                <v-btn :to="{name: 'brand.create'}" class="white--text" color="indigo">
                                    {{ $t('create') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="brand" rules="min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="brand" type="text" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-alpha-b-circle-outline"
                                                  :label="$t('brand_name')" color="primary"
                                                  clearable></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-4">
                        <v-row>
                            <v-col cols="12" class="d-flex justify-center justify-sm-end align-self-center">
                                <v-btn type="submit" :disabled="invalid || loading"
                                       :block="$vuetify.breakpoint.xsOnly" color="primary">
                                    {{ $t('search') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
        <v-card flat>
            <v-card-text>
                <v-data-table :headers="headers" :items="brandItems" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalBrands" :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              :loading-text="$t('loading_please_wait')"
                              class="elevation-1" hide-default-footer>
                    <template v-slot:item.logo="{ item }">
                        <v-img v-if="item.logo" :src="item.logo"
                               aspect-ratio="1" class="my-1" width="40" height="40"></v-img>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" class="mr-2" @click="editBrand(item)">
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{$t('edit')}}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" :disabled="item.deleted" @click="deleteBrand(item)">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-pagination v-model="page" :total-visible="totalVisiblePag"
                              :length="pageCount" :disabled="loading"></v-pagination>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex"

    export default {
        name: "Brands",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                brand: null,
                sortBy: "name",
                sortDir: true,
                loading: false,
                options: {},
                page: 1,
                pageCount: 0,
                perPage: 5,
                totalVisiblePag: 5,
                totalBrands: 0,
                brandItems: [],
                headers: [
                    {
                        text: this.$t('brand_name'),
                        align: "left",
                        sortable: true,
                        value: "name"
                    },
                    {
                        text: this.$t('brand_logo'),
                        align: "left",
                        sortable: false,
                        value: "logo",
                        width: 120,
                    },
                    {
                        text: this.$t('actions'),
                        align: "center",
                        value: 'action',
                        sortable: false,
                        width: 120,
                    },
                ],
            }
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
        },
        mounted() {
            this.options.itemsPerPage = this.perPage = this.itemsPerPage
        },
        watch: {
            options: {
                handler() {
                    this.getBrands()
                },
                deep: false
            }
        },
        methods: {
            editBrand(item) {
                this.$router.push({
                    name: 'brand.edit',
                    params: {
                        id: item.id
                    }
                })
            },
            async getBrands(type) {
                this.loading = true
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options;
                let params = {}
                if (sortBy[0] !== undefined) {
                    params.sortBy = sortBy[0];
                }
                if (sortDesc[0] !== undefined) {
                    params.sortDir = sortDesc[0] ? 'asc' : 'desc';
                }
                if (page !== undefined) {
                    if (type === 'search') {
                        params.page = 1
                    } else {
                        params.page = page
                    }
                }
                if (itemsPerPage !== undefined) {
                    params.perPage = itemsPerPage;
                }
                if (this.brand) {
                    params.brand = this.brand
                }
                await this.$http
                    .get("admin/brand", {
                        params: params,
                    })
                    .then(res => {
                        this.brandItems = res.body.data
                        this.page = res.body.meta.current_page
                        this.totalBrands = res.body.meta.total
                        this.pageCount = res.body.meta.last_page
                    })
                    .catch(err => {
                        this.brandItems = []
                        this.totalBrands = 0
                        this.$toastr.error(this.$t('failed_to_get_list_brands'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async deleteBrand(item) {
                if (confirm(this.$t('delete_brand'))) {
                    var _this = this
                    this.loading = true
                    await this.$http
                        .delete(`admin/brand/${item.id}`)
                        .then(res => {
                            this.$toastr.success(this.$t('brand_has_been_deleted'))
                            this.getBrands()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('brand_has_not_been_deleted'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
        }
    }
</script>
